import type { BaseData, PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { MainSubStructureModel, TimeStampFormatter } from 'egenie-utils';
import React from 'react';
import type { MainItem, Summary } from './types';
import type { Store } from './index';
import { Badge, Typography } from 'antd';

export function mainSubStructureModel(context: Store): MainSubStructureModel {
  return new MainSubStructureModel({
    grid: {
      columns: [
        {
          name: '货主',
          key: 'ownerName',
          width: 200,
        },
        {
          name: '唯一码',
          key: 'uniqueCode',
          width: 200,
          formatter: ({ row }) => (
            <Typography.Text
              copyable
              ellipsis
            >
              {row.uniqueCode}
            </Typography.Text>
          ),
        },
        {
          key: 'qualityInspection',
          name: '质检结果',
          width: 80,
          formatter: ({ row }) => (
            row.qualityInspection ? (
              <Badge
                status="success"
                text="正品"
              />
            ) : (
              <Badge
                status="error"
                text="次品"
              />
            )
          ),
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
        },
        {
          key: 'productNo',
          name: '商品编码',
          width: 200,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          name: '质检时间',
          key: 'createTime',
          width: 200,
          formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
        },
        {
          name: '处理人',
          key: 'creatorName',
          width: 150,
        },
        {
          name: '服装类别',
          key: 'cottonDesc',
          width: 80,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'wmsBillRecordId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/qualityResult/index',
    },
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        request<BaseData<Summary>>({
          url: '/api/cloud/wms/rest/quality/cloud/queryTotalNumByParam',
          method: 'post',
          data: filterParams,
        })
          .then((info) => {
            context.summaryData.goodNum = info.data?.goodNum;
            context.summaryData.defectiveNum = info.data?.defectiveNum;
          });

        return request<PaginationData<MainItem>>({
          url: '/api/cloud/wms/rest/quality/cloud/queryByParam',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
  });
}
