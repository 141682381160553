import { NormalProgramme } from 'egenie-utils';
import type { Store } from './index';

export function normalProgramme(context: Store): NormalProgramme {
  return new NormalProgramme({
    filterItems: [
      {
        type: 'dateRange',
        field: 'dateValue',
        label: '质检时间',
        format: 'YYYY-MM-DD',
      },
      {
        type: 'select',
        field: 'ownerIds',
        label: '货主',
        mode: 'multiple',
      },
      {
        type: 'input',
        field: 'uniqueCode',
        label: '唯一码',
      },
      {
        type: 'select',
        field: 'inspection',
        label: '质检结果',
        data: [
          {
            value: '1',
            label: '正品',
          },
          {
            value: '0',
            label: '次品',
          },
        ],
      },
    ],
    count: 6,
    handleSearch: () => context.mainSubStructureModel.onQuery(),
  });
}
