import { Button, Card, Layout, Modal, Typography } from 'antd';
import type { NormalProgramme, MainSubStructureModel } from 'egenie-utils';
import { ExportModal, MainSubStructure, NormalProgrammeComponent, ExportStore } from 'egenie-utils';
import React from 'react';
import { getOwner } from '../../utils';
import styles from './index.less';
import { mainSubStructureModel } from './mainSubStructureModel';
import { normalProgramme } from './normalProgramme';
import { thousandthSeparator } from 'egenie-common';
import { observable } from 'mobx';
import type { Summary } from './types';
import { observer } from 'mobx-react';

export class Store {
  constructor() {
    this.mainSubStructureModel = mainSubStructureModel(this);

    this.normalProgramme = normalProgramme(this);

    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;

    getOwner()
      .then((ownerIds) => this.normalProgramme.filterItems.addDict({ ownerIds }));
  }

  @observable public summaryData: Summary = {
    goodNum: 0,
    defectiveNum: 0,
  };

  public mainSubStructureModel: MainSubStructureModel;

  public exportStore: ExportStore = new ExportStore({ parent: null });

  public normalProgramme: NormalProgramme;

  public handleExport = () => {
    const ids = Array.from(this.mainSubStructureModel.gridModel.selectedIds)
      .join(',');
    const fileName = '质检结果';
    const exportType = 'cloud_quality_query';
    const queryParam = this.normalProgramme.filterItems.params;
    const queryParamShow = this.normalProgramme.filterItems.translateParams.join(' ');

    if (ids) {
      this.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
    } else {
      Modal.confirm({
        title: '提示',
        content: '未选择数据将导出全部数据?',
        onOk: () => {
          this.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
        },
      });
    }
  };
}

const store = new Store();

export default observer(() => {
  return (
    <>
      <Layout className={styles.container}>
        <Layout.Content>
          <Card size="small">
            <NormalProgrammeComponent store={store.normalProgramme}/>
          </Card>
          <div className={styles.summaryContainer}>
            <Typography.Text>
              正品:&nbsp;
              {thousandthSeparator(store.summaryData.goodNum)}
            </Typography.Text>
            <i/>
            <Typography.Text>
              次品:&nbsp;
              {thousandthSeparator(store.summaryData.defectiveNum)}
            </Typography.Text>
            &nbsp;
            &nbsp;
            <Button onClick={store.handleExport}>
              导出
            </Button>
          </div>
          <div className={styles.tableWrapper}>
            <MainSubStructure store={store.mainSubStructureModel}/>
          </div>
        </Layout.Content>
      </Layout>
      <ExportModal store={store.exportStore}/>
    </>
  );
});
